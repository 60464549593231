import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { useInView } from "react-intersection-observer"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const MidiDresses = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)  

  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.collectivetops.com/green-dresses" />
        <title>{descriptionData.sleeveless.pageTitle}</title>
        <meta name="description" content={descriptionData.sleeveless.description1}/>        
      </Helmet>

      <div className="container mt-6 px-12 mx-auto max-w-screen-xl">
                    <p className="text-sm">Find your perfect top with our new <Link to="/search" className="font-bold">search page </Link>!!!!!</p>
      </div>

      <div className="product-container grid grid-cols-2 gap-2 md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} dressType="Green" />
      </div>
      <h1 class="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.sleeveless.heading}
        </h1>
        <p className="">{descriptionData.sleeveless.description1}</p>
      
        <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">

      <p className="text-l mt-5 mb-5 font-bold">
      Every Friday, Collective Tops, a leading fashion brand, releases a wide array of new top styles. This endeavor of ours serves to offer a constant, fresh assortment of trendy fashion pieces that our customers can sift through. Each weekly update introduces unique styles, stepping up the game in the fashion world and keeping our customer's wardrobes up-to-date. That's why we encourage you to visit our store or website on a weekly basis, ensuring that you don't miss out on the latest fashion trends to enhance your personal style statement.
      </p>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "tops_sleeveless" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`


export default MidiDresses
